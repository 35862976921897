<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="mb-0">
            รายการเกมส์ทั้งหมด
          </h4>

          <button
            class="btn btn-primary"
            @click="addProduct"
          >
            <i class="far fa-plus mr-50" /> เพิ่มเกมส์ใหม่
          </button>
        </div>
      </b-card-body>
      <b-table
        striped
        hover
        responsive
        class="position-relative items-center"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(product_name)="data">
          <div class="w-100 text-center">
            <b-avatar
              size="lg"
              :src="data.item.img"
            />

            <p>{{ data.item.product_name }}</p>
          </div>
        </template>

        <template #cell(status)="data">
          <span
            v-if="data.item.status === 0"
            class="status status-offline"
          >
            ปิดใช้งาน
          </span>

          <span
            v-else
            class="status status-online"
          > กำลังใช้งาน </span>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            id="dropdown-3"
            text="Success"
            variant="success"
          >
            <template #button-content>
              <i class="far fa-edit mr-50" /> จัดการ
            </template>
            <b-dropdown-item
              @click="editProduct(data.item)"
            ><i class="fas fa-edit mr-1 text-warning" /> แก้ไข
            </b-dropdown-item>

            <b-dropdown-item
              @click="deleteBank(data.item.id)"
            ><i class="fas fa-trash mr-1 text-danger" /> ลบ
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>

    <!-- Modal -->
    <b-modal
      ref="add-product"
      :title="[product_name ? product_name : 'เพิ่มเกมส์ใหม่' ]"
      ok-title="บันทึกข้อมูล"
      ok-variant="success"
      cancel-title="ย้อนกลับ"
      cancel-variant="warning"
      size="sm"
      @ok.prevent="handleSubmit()"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group label="Product Name">
                <validation-provider
                  #default="{ errors }"
                  name="product name"
                  rules="required"
                >
                  <b-form-input
                    v-model="product_name"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="Product">
                <validation-provider
                  #default="{ errors }"
                  name="product"
                  rules="required"
                >
                  <b-form-input
                    v-model="product"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="Short Name">
                <validation-provider
                  #default="{ errors }"
                  name="short name"
                  rules="required"
                >
                  <b-form-input
                    v-model="short_name"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="Interface">
                <!-- <validation-provider
                  #default="{ errors }"
                  name="interface"
                  rules="required"
                > -->
                <b-form-input
                  v-model="interfaced"
                  type="text"
                />
                <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="Category ID">
                <validation-provider
                  #default="{ errors }"
                  name="category_id"
                  rules="required"
                >
                  <v-select
                    v-model="category_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="number"
                    :options="categoryList"
                    :state="errors.length > 0 ? false : null"
                    :reduce="(number) => number.number"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="Image">
                <b-form-file
                  v-model="img"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  @change="handleFileUpload"
                />
              </b-form-group>

              <div v-if="img">
                <img
                  height="100"
                  :src="img"
                >
              </div>
            </b-col>

            <b-col md="12">
              <b-form-group label="Status">
                <b-form-select
                  v-model="status"
                  :options="optionsStatus"
                  :state="status === 0 ? false : true"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormFile,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BModal,
  BCard,
  BTable,
  BAvatar,
  BFormSelect,
  BPagination,
  BCardBody,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormSelect,
    BPagination,
    BCardBody,
    BDropdown,
    BDropdownItem,
    vSelect,
  },
  data() {
    return {
      product_id: '',
      product_name: '',
      product: '',
      short_name: '',
      interfaced: '',
      category_id: null,
      img: '',
      status: 1,
      required,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      perPage: 20,
      pageOptions: [10, 20, 30],
      fields: [
        {
          key: 'product_name',
          label: 'product name',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'product',
          label: 'product',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'short_name',
          label: 'short name',
          thClass: 'text-center',
          tdClass: 'text-center text-uppercase',
        },
        {
          key: 'interface',
          label: 'interface',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'category_id',
          label: 'category id',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'status',
          label: 'status',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'actions',
          label: 'actions',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      items: [],
      data: [],
      categoryList: [
        { number: 1 },
        { number: 2 },
        { number: 3 },
        { number: 4 },
        { number: 5 },
        { number: 6 },
      ],
      optionsStatus: [
        { value: 0, text: 'ปิด' },
        { value: 1, text: 'Opened' },
      ],
    }
  },
  mounted() {
    this.getProductAll()
    this.getProduct()
    // this.GetHistory()
  },
  methods: {
    async getProductAll() {
      try {
        const { data } = await this.$http.get('akproduct/showall')
        // console.log(data)
        this.items = data.data
      } catch (e) {
        console.log(e)
      }
    },
    async getProduct() {
      try {
        await this.$http.get('akproduct/show?id=42')
        // console.log(data)
        // this.items = data.data
      } catch (e) {
        console.log(e)
      }
    },
    onFiltered(filteredItems) {
      this.items = filteredItems.data
      this.totalRows = filteredItems.data.length
    },
    handleSubmit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const formData = {
            id: this.product_id,
            product_name: this.product_name,
            product: this.product,
            short_name: this.short_name,
            interface: this.interfaced,
            category_id: this.category_id,
            status: this.status,
            img: this.img,
          }
          if (this.product_id) {
            this.$http
              .post('akproduct/update', formData)
              .then(() => {
                this.getProductAll()
                this.Success('ส่งข้อความ สำเร็จ')
                this.$refs['add-product'].hide()
                this.product_name = null
                this.product = null
                this.short_name = null
                this.interfaced = null
                this.category_id = null
                this.img = null
              })
              .catch(error => {
                console.log(error)
                this.show = false
                this.SwalError(error.response.data.message)
              })
          } else {
            this.$http
              .post('akproduct/store', formData)
              .then(() => {
                this.getProductAll()
                this.Success('ส่งข้อความ สำเร็จ')
                this.$refs['add-product'].hide()
                this.product_name = null
                this.product = null
                this.short_name = null
                this.interfaced = null
                this.category_id = null
                this.img = null
              })
              .catch(error => {
                console.log(error)
                this.show = false
                this.SwalError(error.response.data.message)
              })
          }
        }
      })
    },
    handleFileUpload(event) {
      // eslint-disable-next-line prefer-destructuring
      this.selectedFile = event.target.files[0]
      if (this.selectedFile) {
        const reader = new FileReader()
        reader.onload = () => {
          const base64Image = reader.result
          this.img = base64Image
        }
        reader.readAsDataURL(this.selectedFile)
      }
    },
    addProduct() {
      this.$refs['add-product'].show()
    },
    editProduct(val) {
      console.log(val.img)
      this.$refs['add-product'].show()
      this.product_id = val.id
      this.product_name = val.product_name
      this.product = val.product
      this.short_name = val.short_name
      this.interfaced = val.interface
      this.category_id = val.category_id
      this.img = val.img
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
/*   color variables */
$clr-primary: #81d4fa;
$clr-primary-light: #e1f5fe;
$clr-primary-dark: #4fc3f7;
$clr-gray100: #f9fbff;
$clr-gray150: #f4f6fb;
$clr-gray200: #eef1f6;
$clr-gray300: #e1e5ee;
$clr-gray400: #767b91;
$clr-gray500: #4f546c;
$clr-gray600: #2a324b;
$clr-gray700: #161d34;
$clr-pending: #fff0c2;
$clr-pending-font: #a68b00;
$clr-unpaid: #ffcdd2;
$clr-unpaid-font: #c62828;
$clr-paid: #c8e6c9;
$clr-paid-font: #388e3c;
$clr-link: #2962ff;

$radius: 0.5rem;
.status {
  border-radius: $radius;
  background-color: red;
  padding: 0.2rem 1rem;
  text-align: center;
  font-size: 1rem;

  &-online {
    background-color: $clr-paid;
    color: $clr-paid-font;
  }

  &-offline {
    background-color: $clr-unpaid;
    color: $clr-unpaid-font;
  }
}
</style>
